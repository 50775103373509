@import './assets/scss/breakpoints';
@import './assets/scss/helper';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap');

body h1 {
  font-family: 'Poppins', sans-serif;
}

.hug {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding: rem(24);
  min-height: 100vh;
  align-items: center;
  @include md {
    overflow-x: auto;
    margin-right: 0;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }
}

.moi {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  opacity: 0;
  margin-top: rem(30);
  transform: scale(0.9);
  transition: 0.25s all ease-in;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  &.show {
    margin-top: 0;
    transform: scale(1);
    opacity: 1;
  }
  @include md {
    border: rem(2) solid black;
    flex-wrap: nowrap;
    width: rem(920);
    overflow-x: hidden;
  }
}

.text-block {
  padding: rem(24) rem(48) rem(24) rem(24);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .address-and-linkedin {
    padding-top: rem(24);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    svg {
      width: rem(30);
      height: rem(30);
      @include md {
        width: rem(50);
        height: rem(50);
      }
      margin: rem(-2);
    }
    @include md {
      padding: 0;
    }
  }
}

.image-wrapper {
  position: absolute;
  height: 100%;
  width: calc(100% - #{rem(24)});
  left: calc(100% - #{rem(24)});
  top: 0;
  @include md {
    position: relative;
    width: 100%;
    top: auto;
    left: auto;
    padding: 0;
  }
  .image-block {
    background-image: url('./assets/images/thumbnail_IMG_3852.jpg');
    background-size: cover;
    background-position: center center;
    height: 100%;
    width: calc(100% + #{rem(48px)});
    @include md {
      height: rem(420);
      width: 100%;
    }
  }
}
